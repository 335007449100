import { ThemeProps as Prop } from '@powdr/constants';

export const StaticTheme = {
  colors: {
    [Prop.BLACK]: '#000000',
    [Prop.WHITE]: '#FFFFFF',
  },
  shades: {
    [Prop.LIGHTEST]: '#F0F0F0',
    [Prop.LIGHTER]: '#E0E0E0',
    [Prop.LIGHT]: '#CCCCCC',
    [Prop.GRAY]: '#8B8B8B',
    [Prop.GRAYER]: '#696969',
    [Prop.GRAYEST]: '#525257',
    [Prop.DARK]: '#333333',
    [Prop.DARKER]: '#222222',
    [Prop.DARKEST]: '#292929',
  },
  difficulty: {
    [Prop.BEGINNER]: '#73C053',
    [Prop.INTERMEDIATE]: '#30A8E0',
    [Prop.EXPERT]: '#000000',
  },
  social: {
    [Prop.FACEBOOK]: '#3B5998',
    [Prop.EVENTBRITE]: '#F6682F',
  },
  alerts: {
    [Prop.ACTIVE]: '#2176b8',
    [Prop.SUCCESS]: '#73c053',
    [Prop.INFO]: '#0b24fb',
    [Prop.WARNING]: '#FFB400',
    [Prop.DANGER]: '#FE4A49',
  },
  tv: {
    [Prop.BACKGROUND]: '#000000',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.BORDER]: '#FFFFFF',
    [Prop.ICON]: '#64CAC7',
    [Prop.LINK]: '#FFFFFF',
    [Prop.LINK_HOVER]: '#64CAC7',
    [Prop.ODD_EVEN_OFFSET]: '#E5EDED',
    [Prop.SIDE_NAV_ITEM_BG]: '#64CAC7',
    [Prop.SIDE_NAV_ITEM_TXT]: '#000000',
    [Prop.SIDE_NAV_ITEM_ICON]: '#000000',
    [Prop.SIDE_NAV_ITEM_BG_ACTIVE]: '#FFFFFF',
    [Prop.SIDE_NAV_ITEM_TXT_ACTIVE]: '#3b3937',
    [Prop.SIDE_NAV_ITEM_ICON_ACTIVE]: '#3b3937',
    [Prop.TOP_NAV_ITEM_TXT]: '#FFFFFF',
    [Prop.ALERT_MARQUEE_BG]: '#FFD200',
    [Prop.ALERT_MARQUEE_ALERT_TXT]: '#000000',
    [Prop.ALERT_MARQUEE_TEMP_TIME_TXT]: '#000000',
    [Prop.ALERT_OVERLAY_BG]: '#FFD200',
    [Prop.ALERT_OVERLAY_TXT]: '#000000',
    [Prop.ALERT_OVERLAY_TEMP_TIME_TXT]: '#000000',
    [Prop.LIFTS_TXT]: '#FFFFFF',
    [Prop.LIFTS_ICON]: '#64CAC7',
    [Prop.EVENT_1]: '#FFFFFF',
    [Prop.EVENT_2]: '#FFFFFF',
  },
};
